
import { getOrder } from '@/api/order';
import { getUserInfo } from '@/api/user';
import { getUsableCouponList } from '@/api/userCoupon';
import { storageKey } from '@/enums/localStorage';
import { OrderModule } from '@/store/modules/order';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getShopPickupTimes } from '@/api/shop';

@Component({
  name: 'ProductPayment',
})

export default class extends Vue {
  mounted() {
    if (OrderModule.orderForm.count === 0) {
      this.$router.push({ name: 'Order' });
    }
    this.getCustomDietDetail();
    this.getUsableCouponList();
    this.getUserInfo();
    this.getPickupTimes();
    this.originAmount = this.orderForm.amount;
  }

  private orderForm = OrderModule.orderForm;

  private originAmount = 0;

  // private tossPayments = (window as any).TossPayments('live_ck_4vZnjEJeQVxGBjMzwEM3PmOoBN0k');
  // private tossPayments = (window as any).TossPayments('test_ck_jZ61JOxRQVEP7ldkoBR3W0X9bAqw');
  private tossPayments = (window as any).TossPayments(`${process.env.VUE_APP_TOSS_KEY}`);

  private loading = true;

  private times = [
    {
      value: '오후 4:00 ~ 오후 4:15',
      label: '오후 4:00 ~ 오후 4:15',
    },
    {
      value: '오후 4:15 ~ 오후 4:30',
      label: '오후 4:15 ~ 오후 4:30',
    },
  ];

  private couponList = [];

  private userInfo: any = {
    point: 0,
  }

  private time = '';

  private payMethod = 1;

  private payType = '';

  private payTypes = [
    {
      value: '일시불',
      label: '일시불',
    },
    {
      value: '할부',
      label: '할부',
    },
  ];

  private customDiet = {
    id: 0,
    dietType: 1,
    dietPurpose: 3,
    dietPrecaution: '',
    dietQuantity: 5,
    dietWeek: 4,
    dietPeriod: 1,
    paymentStatus: false,
    orderDate: '',
    orderList: [],
  };

  private shop: any = {
    idx: null,
    name: '',
    address: '',
    addressDetail: '',
  }

  private pickupTimes = [];

  private paymentVisible = false;

  private getCustomDietDetail() {
    this.loading = false;
    // const dietId: string = this.$route.query.dietId;
    /* if (dietId) {
      getCustomDietDetail(dietId).then((res) => {
        this.loading = false;
        this.customDiet = res.data;
      });
    } else {
      this.$notify.error('주문정보가 존재하지 않습니다.');
      this.$router.push({ name: 'Check' });
    } */
  }

  private handleChooseCoupon() {
    if (this.originAmount - this.orderForm.usePoint - this.getCouponDiscountPrice() < 100) {
      this.$message.info('쿠폰 할인금액은 결재 금액보다 클 수 없습니다.');
      this.orderForm.useCouponIdx = null;
    }
  }

  private handleUsePoint() {
    if (this.orderForm.usePoint < 0) {
      this.orderForm.usePoint = 0;
    }
    if (this.orderForm.usePoint > this.orderForm.amount - this.getCouponDiscountPrice() - 100) {
      this.$message.info('최소 100원 이상은 구매하셔야 합니다.');
      this.orderForm.usePoint = this.orderForm.amount - this.getCouponDiscountPrice() - 100;
    }
    if (this.orderForm.usePoint > this.userInfo.point) {
      this.orderForm.usePoint = this.userInfo.point;
    }
  }

  private handleChangePoint() {
    if (this.orderForm.usePoint % 10 !== 0) {
      this.$message.info('10포인트 단위로 입력해 주세요.');
      this.orderForm.usePoint -= this.orderForm.usePoint % 10;
    }
  }

  private handleUseAllPoint() {
      if (this.orderForm.amount < this.userInfo.point + this.getCouponDiscountPrice()) {
        this.$message.info('포인트 사용은 총결제 금액을 초과할 수 없습니다.');
        this.orderForm.usePoint = this.orderForm.amount - this.getCouponDiscountPrice() - 100;
      } else if (this.orderForm.amount - (this.userInfo.point + this.getCouponDiscountPrice()) < 100) {
        this.$message.info('최소 100원 이상은 구매하셔야 합니다.');
        this.orderForm.usePoint = this.orderForm.amount - this.getCouponDiscountPrice() - 100;
      } else {
        this.orderForm.usePoint = this.userInfo.point;
      }
      this.orderForm.usePoint -= this.orderForm.usePoint % 10;
  }

  private handleOrder() {
    if (!this.orderForm.pickupTime) {
      this.$message.warning('픽업시간을 선택해주세요.');
      return;
    }
    this.shop = JSON.parse((window as any).localStorage.getItem(storageKey.pickUpPlace));
    this.loading = true;

    getOrder({
      ...this.orderForm,
      shopId: this.shop.idx,
      orderType: 'PICKUP',
    }).then((res) => {
      this.tossPayments.requestPayment('카드', { // 결제 수단 파라미터
        // 결제 정보 파라미터
        amount: res.data.amount,
        orderId: res.data.key,
        orderName: '와로샐러드',
        successUrl: `${process.env.VUE_APP_TOSS_SUSSCESS}`,
        failUrl: `${process.env.VUE_APP_TOSS_FAIL}`,
        useAppCardOnly: false,
      }).catch((error: any) => {
        this.$message.warning(error.message);
        this.loading = false;
        if (error.code === 'USER_CANCEL') {
          this.$router.push({ name: 'Home' });
          // 결제 고객이 결제창을 닫았을 때 에러 처리
        } else if (error.code === 'INVALID_CARD_COMPANY') {
          this.$router.push({ name: 'Home' });
          // 유효하지 않은 카드 코드에 대한 에러 처리
        }
      });
    });
  }

  private getUsableCouponList() {
    getUsableCouponList().then((res) => {
      this.couponList = res.data;
    });
  }

  private getUserInfo() {
    getUserInfo().then((res) => {
      this.userInfo = res.data;
    });
  }

  private getPickupTimes() {
    this.shop = JSON.parse((window as any).localStorage.getItem(storageKey.pickUpPlace));
    if (!this.shop) {
      this.$message.warning('픽업매장을 선택해주세요.');
      this.$router.push({ name: 'Home' });
    }
    getShopPickupTimes(this.shop.idx).then((res) => {
      this.pickupTimes = res.data;
    });
  }

  /* eslint-disable */
  private getCouponDiscountPrice() {
    let discountPrice = 0;

    if (this.orderForm.useCouponIdx) {
      const idx = this.couponList.findIndex((coupon: any) => coupon.idx === this.orderForm.useCouponIdx);
      if (idx > -1) {
        const useCoupon: any = this.couponList[idx];
        if (useCoupon.percentStatus) discountPrice = this.orderForm.amount * useCoupon.discountPercent / 100;
        else discountPrice = useCoupon.discountPrice;
      }
    }
    return discountPrice;
  }
  /* eslint-enable */
}
